








import { Component, Vue } from "vue-property-decorator";
import DetalleGaleria from "@/components/Galeria/DetalleGaleria.vue";

@Component({
  components: {
    DetalleGaleria
  },
  meta: {
    // sets document title
    title: "Galería - Municipalidad de Quilpué",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Revisa aquí las últimas imagenes."
      },
      keywords: { name: "keywords", content: "Quilpué" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class DetalleGaleriaView extends Vue {
  private mounted() {
    document.title = "Galería - Municipalidad de Quilpué";
  }
}
